@import '~bootstrap/dist/css/bootstrap.min.css';



input
{
    width: 30%;
}

th
{
    text-align: center;
}

body
{
    margin: 1rem;

}

h3{
    display: flex;
    justify-content: center;
}

.card
{
    display: flex;
    gap: 1rem;
    margin-top: 20px;
    border: none;
}

.table>:not(caption)>*>* {
    padding: .5rem 2.5rem !important;
}


@media (min-width: 768px){
    .table-view{
        display: block;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        
    }
    .card-view{
        display: none;
    }
    body{
        background-color: rgb(175, 221, 253);
    }
}
@media (max-width: 767px){
    .card-view{
        display: block;
    }
    .table-view{
        display: none;
    }
    .ant-card{
        box-shadow: rgba(100, 63, 87, 0.35) 0px 5px 15px;
    }
    .searchBar
    {
        width: 80%;
    }

}